.container {
  max-width: var(--maxWidth);
  margin: auto;
  padding: 20px 20px 100px;
}

.title {
  color: var(--veryDarkGray);
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.4px;
}

.day {
  padding-top: 40px;
  padding-bottom: 5px;
  color: var(--magenta);
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}
