.header {
  position: relative;
  height: var(--maxWidth);
  max-height: calc(100vh - 250px);
  padding: 40px 20px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-color: var(--veryLightGray);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 100%);
  }
}

.intro {
  position: relative;
  font-family: 'Barlow Condensed';
  font-weight: bold;
  font-size: 16px;
  opacity: 0.8;
}

.name {
  position: relative;
  font-weight: bold;
  font-size: 33px;
  line-height: 1;
  margin-left: -2px;
  margin-top: 7px;
}

.details {
  position: relative;
  margin-top: 14px;
  font-size: 13px;
}

.sponsorBar {
  background-color: var(--magenta);
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.logo {
  height: 40px;
  width: auto;
}

.lessons {
  padding-top: 20px;
  padding-bottom: 100px;
}
