/* vietnamese */
@font-face {
  font-family: 'BarlowSemiCondensed-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Barlow Semi Condensed Regular'),
    local('BarlowSemiCondensed-Regular'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXd4qqKkkog6SP.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'BarlowSemiCondensed-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Barlow Semi Condensed Regular'),
    local('BarlowSemiCondensed-Regular'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXdoqqKkkog6SP.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'BarlowSemiCondensed-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Barlow Semi Condensed Regular'),
    local('BarlowSemiCondensed-Regular'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXeIqqKkkogw.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'BarlowSemiCondensed-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Barlow Semi Condensed Medium'),
    local('BarlowSemiCondensed-Medium'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6m_CGslqZ6GfVK5.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'BarlowSemiCondensed-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Barlow Semi Condensed Medium'),
    local('BarlowSemiCondensed-Medium'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6m_CWslqZ6GfVK5.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'BarlowSemiCondensed-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Barlow Semi Condensed Medium'),
    local('BarlowSemiCondensed-Medium'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6m_B2slqZ6GfQ.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'BarlowSemiCondensed-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('Barlow Semi Condensed SemiBold'),
    local('BarlowSemiCondensed-SemiBold'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66_CGslqZ6GfVK5.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'BarlowSemiCondensed-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('Barlow Semi Condensed SemiBold'),
    local('BarlowSemiCondensed-SemiBold'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66_CWslqZ6GfVK5.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'BarlowSemiCondensed-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('Barlow Semi Condensed SemiBold'),
    local('BarlowSemiCondensed-SemiBold'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66_B2slqZ6GfQ.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'BarlowSemiCondensed-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Barlow Semi Condensed Bold'), local('BarlowSemiCondensed-Bold'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-_CGslqZ6GfVK5.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'BarlowSemiCondensed-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Barlow Semi Condensed Bold'), local('BarlowSemiCondensed-Bold'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-_CWslqZ6GfVK5.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'BarlowSemiCondensed-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Barlow Semi Condensed Bold'), local('BarlowSemiCondensed-Bold'),
    url(https://fonts.gstatic.com/s/barlowsemicondensed/v6/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-_B2slqZ6GfQ.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
