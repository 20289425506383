@import './fonts/Barlow.css';
@import './fonts/BarlowSemiCondensed.css';
@import './fonts/BarlowCondensed.css';
@import './fonts/NeuePlak.css';

:root {
  --white: #fff;
  --lowContrastGray_USE_WITH_WISDOM: #f5f5f5;
  --veryLightGray: #e0e0e0;
  --lightGray: #bdbdbd;
  --gray: #9e9e9e;
  --darkGray: #757575;
  --veryDarkGray: #212121;
  --black: #000;
  --magenta: #e71549;
  --darkMagenta: #bf1741;
  --lightBlue: #83b0c9;

  /* limite de segurança enquanto não temos layout responsivo */
  --maxWidth: 400px;
}

body {
  max-width: var(--maxWidth);
  margin: auto;
  font-family: Barlow, sans-serif;
  color: var(--veryDarkGray);
  background-color: var(--veryLightGray);
}

a,
button,
input {
  -webkit-tap-highlight-color: transparent;
  font-family: inherit;
  color: inherit;
}
