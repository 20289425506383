.header {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.picture {
  width: 28px;
  height: 28px;
  border-radius: 28px;
}
