.header {
  padding: 20px;
  background-color: var(--white);

  * {
    outline: none;
  }
}

.container {
  padding: 0 20px;
  box-sizing: border-box;
  background-color: var(--white);
}

.wrapper {
  max-width: 600px;
  margin: auto;
  padding-bottom: 60px;
}

.title {
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0 0;
}

.subtitle {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0 0;
}

.button {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--white);
  color: var(--darkGray);
  padding: 13px 15px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    background-color: var(--lowContrastGray_USE_WITH_WISDOM);
  }
}

.flowSection {
  margin: 30px -20px;
  padding: 0 20px;
  border-bottom: 1px solid var(--magenta);
}

.flowButton {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--darkGray);
  background-color: transparent;
  padding: 13px 15px;
  outline: none;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;

  margin-right: 5px;
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: -1px;

  &:hover {
    color: var(--veryDarkGray);
  }

  &.selected {
    pointer-events: none;
    border-color: var(--magenta);
    border-bottom-color: var(--white);
    color: var(--magenta);
  }
}

.form {
  margin: 30px 0;
}

.field {
  display: block;
  margin: 30px 0;
  color: var(--darkGray);
}

.label {
  text-transform: uppercase;
  font-size: 12px;
}

.input {
  font-size: 18px;
  line-height: 35px;
  height: 35px;
  color: var(--veryDarkGray);
  padding: 0;
  border: none;
  border-bottom: 1px solid var(--gray);
  background: none;
  outline: none;
  width: 100%;
}

.caption {
  font-size: 12px;
  margin-top: 3px;
}

.submitButton {
  background-color: var(--magenta);
  color: white;
  font-size: 14px;
  padding: 16px 28px;

  &:hover {
    background-color: var(--darkMagenta);
    color: white;
  }
}

.status {
  background: white;
  padding: 20px 0;
  border-radius: 5px;
  margin: 30px 0;
  font-size: 14px;
}

.statusItem {
  margin: 10px 0;
}

.restartButton {
  background-color: white;
  border: 1px solid var(--magenta);
  color: var(--magenta);
}
