.container {
  height: calc(100vh - 60px);
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.loading,
.error {
  text-align: center;
}

.card {
  position: relative;
  display: block;
  width: 100%;
  max-width: var(--maxWidth);
  background-color: white;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}

.header {
  position: relative;
  height: 450px;
  max-height: calc(100vh - 160px);
  padding: 40px 20px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  color: white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 27%, rgba(0, 0, 0, 0.6) 100%);
  }
}

.name {
  position: relative;
  font-weight: bold;
  font-size: 46px;
  line-height: 1;
  margin-left: -2px;
}

.details {
  position: relative;
  margin-top: 15px;
  font-weight: 600;
  font-size: 13px;
}

.footer {
  background-color: var(--magenta);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.status {
  font-size: 13px;
  line-height: 1;
  margin-bottom: 3px;
}

.button {
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--darkMagenta);
  padding: 11px 12px;
  color: white;
  text-transform: uppercase;
  font-family: 'Barlow Condensed';
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 1px;

  > svg {
    margin-top: -2px;
    margin-right: 6px;
  }
}
