.container {
  padding: 20px;
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
  margin-bottom: 10px;
}

.lessonDate {
  font-size: 18px;
  margin-bottom: 40px;
}

.message {
  margin: 0 -10px 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--white);
}

.name {
  font-weight: bold;
}

.time {
  float: right;
  margin-bottom: 5px;
  font-size: 11px;
}
