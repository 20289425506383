.container {
  height: 100vh;
  overflow-y: auto;
  background: linear-gradient(
    180deg,
    var(--white) 0%,
    var(--lowContrastGray_USE_WITH_WISDOM) 50%
  );
  background-attachment: fixed;
}
