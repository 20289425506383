@font-face {
  font-family: 'NeuePlak-CompLight';
  font-style: normal;
  font-weight: 300;
  src: local('Neue Plak Compressed Light'), local('NeuePlak-CompLight'),
    url('./Neue-Plak-Compressed-Light.woff') format('woff');
}

@font-face {
  font-family: 'NeuePlak-CompBold';
  font-style: normal;
  font-weight: 700;
  src: local('Neue Plak Compressed Bold'), local('NeuePlak-CompBold'),
    url('./NeuePlak-CompBold.otf') format('otf');
}
