.title {
  padding: 20px;
  font-weight: 700;
  font-size: 14px;
}

.gridWindow {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
}

.gridPadding {
  padding: 20px;
}

.gridContent {
  position: relative;
}

.spot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 48px;
  border-radius: 4px;
  padding: 0;
  border: none;
  outline: none;
  position: absolute;
  font-weight: 700;
  font-size: 14px;

  &.selected {
    background-color: var(--magenta);
    color: var(--white);
  }

  &.available {
    background-color: var(--white);
    color: var(--magenta);
    border: 2px solid var(--veryLightGray);
  }

  &.unavailable {
    background-color: var(--veryLightGray);
    color: var(--white);
  }
}
