.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black);
}

.error {
  @extend .fullscreen;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}
